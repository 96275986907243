import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { APIURL, CONSTNAME, VARS } from 'src/app/allServices/constant.service';
import { BehaviorSubject, fromEvent, Subject, Subscription } from "rxjs";
import { commonservice } from 'src/app/allServices/commonservice';
import { MatChipInputEvent } from "@angular/material/chips";
import { FormControl } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { DeleteComponent } from '../MODAL/delete/delete.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmComponent } from '../MODAL/confirm/confirm.component';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { ZipuploadService } from 'src/app/allServices/zipupload.service';
@Component({
  selector: 'app-upload-loop',
  templateUrl: './upload-loop.component.html',
  styleUrls: ['./upload-loop.component.css']
})
export class UploadLoopComponent implements OnInit {
  @ViewChild('fileInput') fileInput!: ElementRef;
  @ViewChild('fileInput1') fileInput1!: ElementRef;
  @ViewChild('fileInput2') fileInput2!: ElementRef;
  @ViewChild('fruitInput') fruitInput: ElementRef<HTMLInputElement>;
  loopprogress: boolean;
  disable_input_as_itsloading = false;
  user_id: any;
  durationofaudio: any;
  percentage: number = 0;
  private cancelUpload = new Subject<void>();
  private cancelUpload2 = new Subject<void>();
  private cancelUpload3 = new Subject<void>();
  progress: any;
  tag1: any;
  tag2: any;
  tag3: any;
  removable = true;
  selectable = true;
  dragClass: string;
  visiblity: any = 0
  fruits: string[] = [];
  tagged_loop: any;
  tagvalid = false;
  cancelUpload1 = new Subject<void>();
  fruitCtrl = new FormControl();
  keys: any = ['A Major',
    'A Minor',
    'B Major',
    'B Minor',
    'C Major',
    'C Minor',
    'D Major',
    'D Minor',
    'E Major',
    'E Minor',
    'F Major',
    'F Minor',
    'G Major',
    'G Minor',
    'A♭ Major',
    'A♭ Minor',
    'B♭ Major',
    'B♭ Minor',
    'C♭ Major',
    'C♭ Minor',
    'D♭ Major',
    'D♭ Minor',
    'E♭ Major',
    'E♭ Minor',
    'F♭ Major',
    'F♭ Minor',
    'G♭ Major',
    'G♭ Minor',
    'A# Major',
    'A# Minor',
    'B# Major',
    'B# Minor',
    'C# Major',
    'C# Minor',
    'D# Major',
    'D# Minor',
    'E# Major',
    'E# Minor',
    'F# Major',
    'F# Minor',
    'G# Major',
    'G# Minor'
  ]
  main_keys: any = [{ key: '0', name: "Major" }, { key: '1', name: "Minor" }];
  noDraftsMessage: string;
  drafts: any = [];
  profile_image_pathofcomments = "assets/img/artwork.jpg";
  loop_id: any;
  loop: string | Blob;
  usage: any;
  sliderValue: any = 0.00
  noDrafts: boolean;
  zipProgress: boolean;
  stempercentage: number;
  reader: FormData;
  disable_input_as_itsloading2: boolean = true;
  fileerrorformandatory: boolean;
  nameoffile: any;
  uploadcompletestatus: string;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  zipfile: any;
  zipfilename: any;
  stems: any;
  player: any;
  total: any;
  above15second: boolean;
  apihitforstream: boolean;
  above5secondlessthan15: boolean;
  above1secondlessthan5: boolean;
  id: NodeJS.Timeout;
  imgpercentage: number;
  imageProgress: boolean;
  image: any;
  ImagefileName: any;
  imageuploaded: any;
  editname: boolean;
  showinput: boolean;
  fileslength: any;
  prev: any = true;
  bpm: number | null;
  get_usage: any;
  showWave: boolean;
  private navigationSubscription: Subscription;
  tokkenget: any = localStorage.getItem("clientintoken");
  upload: any = true;
  prog: boolean = false;  // Variable to control navigation
  applyall: boolean;
  default_usage: any;
  private visibilityChange$ = new BehaviorSubject<boolean>(true);
  private isVisible = true; // Track visibility state
  private processQueue: File[] = []; // Queue of files to process
  private currentIndex = 0; // Track current file index
  constructor(private router: Router, private http: HttpClient, private zipUploadService: ZipuploadService, private el: ElementRef, private toastr: ToastrService, public common: commonservice, public matDialog: MatDialog,
  ) {
    this.initVisibilityChangeListener();
    this.navigationSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        // Navigation is about to start, handle cleanup logic
        this.handleCleanup();
      } else if (event instanceof NavigationEnd) {
        // Navigation has ended, perform any necessary actions
        // (e.g., trigger the click event)
        this.handleNavigationEnd();
      }
    });
  }
  private initVisibilityChangeListener() {
    // fromEvent(document, 'visibilitychange').pipe(
    //   debounceTime(100)
    // ).subscribe(() => {
    //   this.isVisible = !document.hidden;
    //     console.log(this.isVisible);
    //     // if (this.isVisible) {
    //     // If the tab becomes visible, resume processing
    //     this.processNextFile();
        
    //   // }
    // });
    // Set up a regular interval to process files regardless of tab visibility
  setInterval(() => {
    this.processNextFile();  // Keep processing files at regular intervals
  }, 1000);  // Process a file every second, for example
  }
  private handleCleanup() {
    // Perform cleanup logic before navigation
    // (e.g., pausing audio or video playback)
    const element = this.el.nativeElement.querySelector("#player-row-" + this.player + " .play_button.pause");
    if (element) {
      element.click();
    }

    // ////////console.log('Performing cleanup before navigation');
  }

  private handleNavigationEnd() {
    // Navigation has ended, perform necessary actions
    // (e.g., trigger the click event)
    // ////////console.log('Navigation has ended, triggering click event');

    const element = this.el.nativeElement.querySelector("#player-row-" + this.player + " .play_button.pause");
    if (element) {
      element.click();
    }
  }


  ngOnInit(): void {


    this.common.getDataInCookie(VARS.Login_Client_data).then((res: any) => {
      let resa = JSON.parse(res);
      // ////console.log(localStorage.getItem('usage_button'));
      if (resa) {
        this.get_usage = localStorage.getItem("client_data")
        // console.log(JSON.parse(this.get_usage));

        const right = JSON.parse(this.get_usage).usage_rights
        if (right) {
          this.default_usage = right
        } else {
          this.usage = resa.usage_rights
        }
        // console.log(this.default_usage);
      }
    })
    this.getDrafts()
    const fileInput = document.getElementById('fileInput');

    fileInput?.addEventListener('mouseover', function (event) {
      event.preventDefault();
      // Custom logic here
      // console.log('Mouse over event triggered.');
    });
  }
  async ngOnDestroy() {
    // Show confirmation dialog
    this.drafts.map((i: any) => {
      if (i.zipProgress === true) {
        this.prog = true
        this.toastr.error("Zip upload is in progress", "", { positionClass: 'toast-bottom-right' });
        return
      }
    })
    // console.log(this.prog);

    if (!this.prog) {
      // console.log(this.upload);
      if (!this.upload) {
        const userConfirmed = window.confirm("Are you sure you want to leave this page? All changes will be lost.");

        if (userConfirmed) {
          // User confirmed, proceed with sending data
          this.user_id = localStorage.getItem("client_data");
          if (this.user_id) {
            const parsedUserId = JSON.parse(this.user_id).user_type_id;

            const sendData = {
              "user_id": parsedUserId,
            };

            const headers: any = {
              Authorization: `Bearer ${this.tokkenget}`,
              "My-Custom-Header": "foobar",
            };

            this.http
              .post(`${APIURL.BASE_URL}/user/delete_drafts`, sendData, {
                headers: headers,
              })
              .pipe()
              .subscribe(
                (event: any) => {
                  // Handle success if needed
                  //console.log('Drafts deleted successfully.');
                },
                (error) => {
                  // Handle error if needed
                  //console.error('Error deleting drafts:', error);
                }
              );
          } else {
            //console.error('No user data found in local storage.');
          }
        } else {
          // User canceled, do nothing
          this.router.navigate(['/upload-loop']);
          //console.log('User canceled the operation.');
          // this.zipUploadService.setZipUploadInProgress(true);

        }
      }
    }
  }
  canNavigate(): boolean {
    return !this.prog;
  }
  triggerFileInput() {

    if (this.fileInput && this.fileInput.nativeElement && !this.loopprogress) {
      this.fileInput.nativeElement.click();
    } else {
      this.toastr.error("Please allow your current files to be uploaded before adding more", "", { positionClass: 'toast-bottom-right' });

    }
  }
  triggerImgInput(i: any) {
    const file = document.getElementById(`imginput${i}`)
    if (file) {
      file.click();
    }
  }
  waveformLoaded: boolean[] = [];

  onWaveformLoaded(index: number) {
    this.waveformLoaded[index] = true;
  }

  triggerFileInput2(i: any) {
    const play = document.getElementById("#play_button")
    const file = document.getElementById(`fileinput${i}`)

    //console.log(file);

    if (file && file && !play) {
      file.click();
    }
  }
  srcforsound(items: any) {
    // console.log(items);

    if (items.loop_type == 1) {
      if (items.tagged_loop_path !== null) {
        this.durationofaudio = items.tagged_loop_duration;
        return CONSTNAME.fILE_PATH + items.tagged_loop_path;
      } else {
        this.durationofaudio = items.loop_duration;
        return CONSTNAME.fILE_PATH + items.loop_path;
      }
    } else {
      this.durationofaudio = items.tagged_loop_duration;
      return CONSTNAME.fILE_PATH + items.tagged_loop_path;
    }
  }
  onSliderValueChanged(value: number) {
    this.sliderValue = value; // Update sliderValue in the parent component

  }

  setimage(data: any) {
    if (data.artwork_path == null) {
      if (data.profile_image_path == null) {

        return this.profile_image_pathofcomments;
      } else {
        if (data.profile_image_path.includes("/profile_photos/")) {
          return CONSTNAME.fILE_PATH + data.profile_image_path;

        } else
          return data.profile_image_path;
      }
    } else {
      return CONSTNAME.fILE_PATH + data.artwork_path;
    }
  }
  deleteZipFile(i: any,loop:any) {
    // ////console.log("closeeee");
    // this.disable_input_as_itsloading = false;
    this.drafts[i].disable_input_as_itsloading2 = false;

    this.drafts[i].zipProgress = false;
    this.drafts[i].zipfilename = false;
    let tokkenget: any = localStorage.getItem("clientintoken");
    
    let headers: any = { Authorization: `Bearer ${tokkenget}`, "My-Custom-Header": "foobar" };
    this.http
      .post(`${APIURL.BASE_URL}/user/delete_zip`,{loop_id: loop} ,{
        headers: headers,
      })
      .subscribe((e: any) => {

      })
  }

  selectFileZip(event: any, loop: any, i: any) {
    // ////console.log(event.target.files[0].type);
    this.zipUploadService.setZipUploadInProgress(true);
    this.reader = new FormData();
    this.drafts[i].zipProgress = true;
    // this.drafts[i].disable_input_as_itsloading2 = true;
    let id: any = document.getElementById("fileinput");
    // ////console.log("gaya")
    if (event.target.files[0] == undefined) {
      // this.url = "assets/img/images.png";
      // ////console.log("cancel");
      this.fileerrorformandatory = false;
      this.drafts[i].zipProgress = false;
      id.innerHTML = "";
      // this.canDeactivate = true;

    } else {
      let extensiontype = event.target.files[0].name.split('.').pop();;
      // (extensiontype);

      if (extensiontype == 'rar' || extensiontype == 'zip') {


        if (event.target.files[0].type == '' || event.target.files[0].type == 'application/x-zip-compressed' || event.target.files[0].type == 'application/zip') {
          // this.canDeactivate = true;
          // (event.target.files[0].size );

          if (event.target.files[0].size == 0 || event.target.files[0].size > 3221225472) {
            this.fileerrorformandatory = false;
            id.innerHTML = "File size cannot be 0 mb or above 3 Gb";
            this.toastr.error("File size cannot be 0 mb or above 3 Gb", "", { positionClass: 'toast-bottom-right' })
            this.drafts[i].zipProgress = false;
            this.drafts[i].disable_input_as_itsloading2 = false;


          } else {
            if (event.target.files) {
              // ("i am in");
              this.progress = 0;
              this.changeProgress();
              this.nameoffile = event.target.files[0].name;
              setTimeout(() => {
                this.uploadcompletestatus = "Upload Complete";
              }, 1000);
              this.zipfile = event.target.files[0];
              this.drafts[i].zipfilename = this.zipfile.name;
              // console.log(event.target.files[0].name, this.zipfile, this.drafts[i].zipfilename);


              this.fileerrorformandatory = true;
              id.innerHTML = "";

              this.reader.append('stems', this.zipfile, this.zipfilename);

              if (this.loop_id !== "") {
                this.reader.append("loop_id", loop);
              }


              this.user_id = localStorage.getItem("client_data");
              let tokkenget: any = localStorage.getItem("clientintoken");
              const user = JSON.parse(this.user_id).user_type_id;


              let headers: any = { Authorization: `Bearer ${tokkenget}`, "My-Custom-Header": "foobar" };
              this.http
                .post(`${APIURL.BASE_URL}/user/upload_loop_stems`, this.reader, {
                  headers: headers,
                  reportProgress: true,
                  observe: "events"
                }).pipe(takeUntil(this.cancelUpload2))
                .subscribe((e: any) => {
                  if (e.type === HttpEventType.UploadProgress && e.loaded && e.total) {
                    const newPercentage = Math.round((e.loaded / e.total) * 100);
                    //console.log(newPercentage);

                    if (this.drafts[i]?.stempercentage !== newPercentage) {
                      this.drafts[i].stempercentage = newPercentage;
                    }

                    if (this.stempercentage === 100) {
                      this.drafts[i].zipProgress = false;
                    }
                  }
                  // ////console.log('e.type',e.type );
                  if (e.type === HttpEventType.Response) {
                    // ////console.log('e.type',e.body?.response?.status );
                    // Handle the final response here if needed
                    if (e.body?.response?.status) {
                      // ////console.log('e.type',e.body?.response?.data );
                      this.drafts[i].zipProgress = false;
                      this.drafts[i].stempercentage = 0
                      this.drafts[i].stems = e.body.response.data
                      this.drafts[i].disable_input_as_itsloading2 = true;
                      this.zipUploadService.setZipUploadInProgress(false);

                      this.getDrafts()
                    }
                  }
                  // ("event:", event);
                });

            }
          }
        } else {
          id.innerHTML = "Only .zip or .rar media is supported ";
        }
      } else {
        id.innerHTML = "Only .zip or .rar media is supported ";
        this.zipProgress = false;
        this.toastr.error("Only .zip or .rar media is supported", "", { positionClass: 'toast-bottom-right' })

      }
    }
  }
  changeProgress() {
    setTimeout(() => {
      if (this.progress < 100) {
        this.uploadcompletestatus = "Uploading"
        this.progress = this.progress + 10;
        this.changeProgress()
      } else if (this.progress == 100) {
        this.uploadcompletestatus = "Upload Complete";
        this.dragClass = '';
      }
    }, 100);
  }
  MAX_ARTWORK_SIZE_MB = 5;
  SUPPORTED_ARTWORK_TYPES = ['jpg', 'jpeg', 'png'];

  isValidArtworkSize(file: { size: number; }) {
    return file.size <= this.MAX_ARTWORK_SIZE_MB * 1024 * 1024;
  }

  isValidArtworkFile(file: { name: string; }) {
    let fileExtension: any
    fileExtension = file.name.split('.')?.pop()?.toLowerCase();
    return this.SUPPORTED_ARTWORK_TYPES.includes(fileExtension);
  }
  MAX_FILE_SIZE_MB = 100;

  isValidFileSize(file: { size: number; }) {
    return file.size <= this.MAX_FILE_SIZE_MB * 1024 * 1024;
  }
  SUPPORTED_FILE_TYPES = ['wav', 'mp3'];

  isValidFileType(file: { name: string; }) {
    let fileExtension: any
    fileExtension = file.name.split('.').pop()?.toLowerCase();
    return this.SUPPORTED_FILE_TYPES.includes(fileExtension);
  }
  extractduration(audio: any) {
    return audio.duration
  }
  private isUploading = false; // Flag to track upload status

  private async processNextFile() {
    // Check if all files have been processed
    if (this.currentIndex >= this.processQueue.length) {
      return; // Exit if no more files to process
    }
  
    const file = this.processQueue[this.currentIndex];
  
    // Proceed only if there's no file currently being uploaded
    if (!this.isUploading) {
      this.zipUploadService.setZipUploadInProgress(true);
      this.currentIndex++; // Move to the next file
      this.isUploading = true; // Set upload flag
  
      try {
        // Use AudioContext for reliable metadata extraction
        const { duration, bpm } = await this.extractAudioMetadata(file);
        this.durationofaudio = duration;
        this.bpm = bpm;
        
        // console.log(`Metadata extracted for file: ${file.name}`);
  
        // Process the file upload
        await this.processFileUpload(file);
  
      } catch (error) {
        // console.error("Error processing file:", error);
      } finally {
        // Reset flags after processing is complete
        this.isUploading = false;
        this.loopprogress = false;
        this.disable_input_as_itsloading = false;
        this.zipUploadService.setZipUploadInProgress(false);
      }
  
      // Continue processing the next file
      this.processNextFile();
    }
  }
  
  // Extract audio metadata using AudioContext instead of relying on DOM events
  private async extractAudioMetadata(file: File): Promise<{ duration: number; bpm: number }> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
  
      // Read the file as an array buffer for AudioContext processing
      reader.onload = async (event) => {
        const arrayBuffer = event.target?.result as ArrayBuffer;
  
        try {
          const audioContext = new AudioContext();
          const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
  
          const duration = audioBuffer.duration; // Get audio duration
          const bpm = await this.extractBPM(file.name); // Extract BPM (existing method)
  
          resolve({ duration, bpm });
        } catch (error) {
          // console.error("Error decoding audio data", error);
          reject(error);
        }
      };
  
      // Handle potential errors during file reading
      reader.onerror = (error) => {
        // console.error("Error reading file:", error);
        reject(error);
      };
  
      reader.readAsArrayBuffer(file); // Start reading the file
    });
  }
  

  async selectFiles(event: any) {
    if (this.loopprogress) {
      this.toastr.error("Upload is in progress", "", { positionClass: 'toast-bottom-right' });
      return
    }
    this.upload = false;
    const files: File[] = Array.from(event.target.files);
    this.fileslength = files.length;

    if (this.drafts.length + files.length > 50) {
      this.toastr.error("Only 50 uploads are Allowed at once", "", { positionClass: 'toast-bottom-right' });
      return;
    }

    if (files.length > 50) {
      this.toastr.error("Maximum 50 loops can be selected at a time. Please reduce the number of selected loops and try again.", "", { positionClass: 'toast-bottom-right' });
      return;
    }

    const invalidFiles = files.filter((file: any) => !this.isValidFileType(file));
    if (invalidFiles.length > 0) {
      this.toastr.error("One or more selected files are not in the supported format (.wav or .mp3). Please upload only .wav or .mp3 files.", "", { positionClass: 'toast-bottom-right' });
      return;
    }

    const oversizedFiles = files.filter((file: any) => !this.isValidFileSize(file));
    if (oversizedFiles.length > 0) {
      this.toastr.error("One or more files exceed the maximum allowable size of 100MB per audio file. Please ensure your audio file is under 100MB.", "", { positionClass: 'toast-bottom-right' });
      return;
    }
    this.processQueue = Array.from(event.target.files);
    this.currentIndex = 0; // Reset index
    this.processNextFile(); // Start processing files

  }

  processFileUpload(fileToUpload: File): Promise<void> {
    return new Promise(async (resolve, reject) => {
      this.disable_input_as_itsloading = true;
      this.loopprogress = true;

      let outputFile: File = fileToUpload;
      let fileName = fileToUpload.name;
      const fileExtension = fileName?.split('.').pop()?.toLowerCase();

      if (fileExtension === 'wav') {
        fileName = fileName.replace(/\.wav$/, '.mp3');
        outputFile = new File([fileToUpload], fileName, { type: fileToUpload.type });
      }

      const formData: FormData = new FormData();
      formData.append("main_loop", fileToUpload);
      formData.append("user_id", JSON.parse(localStorage.getItem("client_data")!).user_type_id);
      formData.append("loop_duration", this.durationofaudio);

      let headers: any = {
        Authorization: `Bearer ${this.tokkenget}`,
        "My-Custom-Header": "foobar",
      };

      this.http.post(`${APIURL.BASE_URL}/user/upload_loop_draft`, formData, { headers, reportProgress: true, observe: "events" })
        .pipe(takeUntil(this.cancelUpload))
        .subscribe(async (event: any) => {
          if (event.type === HttpEventType.UploadProgress && event.loaded && event.total) {
            const newPercentage = ((event.loaded / event.total) * 100) / 2;
            if (this.percentage !== newPercentage) {
              this.percentage = newPercentage
            }
          }
          if (event.type === HttpEventType.Response) {
            if (event.body.response.status) {
              this.user_id = localStorage.getItem("client_data");
              const user = JSON.parse(this.user_id).user_type_id;
              this.loop_id = event.body.response.data.loop_id;
              await this.callstreaming(outputFile, user);

              resolve();
            } else {
              reject(new Error('Upload failed'));
            }
          }
        }, (error) => {
          this.loopprogress = false;
          this.disable_input_as_itsloading = false;
          if (error.status === 0) {
            this.toastr.error("Network connection lost. Please check your connection and try uploading again.", "", { positionClass: 'toast-bottom-right' });
          } else {
            this.toastr.error("An error occurred during the upload. Please try again.", "", { positionClass: 'toast-bottom-right' });
          }
          reject(error);
        });
    });
  }

  callstreaming(outputFile: any, user: any) {
    return new Promise<void>(async (resolve, reject) => {
      let tokkenget: any = localStorage.getItem("clientintoken");
      const formData1: FormData = new FormData();
      formData1.append("loop_id", this.loop_id);
      let headers: any = {
        Authorization: `Bearer ${tokkenget}`,
        "My-Custom-Header": "foobar",
      };
      formData1.append("tagged_loop", outputFile);
      formData1.append("user_id", user);
      formData1.append("tagged_loop_duration", this.durationofaudio);

      this.http.post(`${APIURL.BASE_URL}/user/upload_streaming_loop`, formData1, {
        headers,
        reportProgress: true,
        observe: "events"
      }).pipe(takeUntil(this.cancelUpload)).subscribe((e: any) => {
        if (e.type === HttpEventType.UploadProgress && e.loaded && e.total) {
          const newPercentage = (50 + ((e.loaded / e.total) * 50));
          if (this.percentage !== newPercentage) {
            this.percentage = newPercentage;
          }
        }
        // if (this.percentage === 100) {
        //   this.loopprogress = false;
        // }
        if (e.type === HttpEventType.Response) {
          if (e.body.response.status) {
            if (this.bpm) {
              let sendData = {
                "bpm": this.bpm,
                "loop_id": this.loop_id,
              }
              let headers: any = {
                Authorization: `Bearer ${this.tokkenget}`,
                "My-Custom-Header": "foobar",
              };
              this.http
                .post(`${APIURL.BASE_URL}/user/upload_loop_test`, sendData, {
                  headers: headers,
                })
                .pipe()
                .subscribe((event: any) => {
                  resolve();
                  this.prev = true;
                  this.getDrafts()
                })
            }
          }
        }
      })
    })
  }

  cancelUploadRequest() {
    if (this.cancelUpload) {
      this.cancelUpload.next();
      this.cancelUpload.complete();
    }
    this.zipUploadService.setZipUploadInProgress(false);
    this.isUploading = false
    this.loopprogress = false;
    this.disable_input_as_itsloading = false;
    if (this.percentage > 50) {
      let tokkenget: any = localStorage.getItem("clientintoken");

      let headers: any = {
        Authorization: `Bearer ${tokkenget}`,
        "My-Custom-Header": "foobar",
      };

      this.http
        .post(
          `${APIURL.BASE_URL}/user/delete_upload`,
          { loop_id: this.loop_id },
          {
            headers: headers,
          }
        )
        .pipe()
        .subscribe((event: any) => {
          // ////console.log("event:", event);
          if (event.response.status) {
            this.getDrafts()
          }
        })
    }
  }

  onFilesDrop(entries: NgxFileDropEntry[]) {
    // entries.forEach(entry => {
    //   if (entry.file) {
    //     const file = entry.file as File;
    //     this.selectFiles({ target: { files: [file] } });
    //   }
    // });
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    this.dragClass = 'drag-over';
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    this.dragClass = '';
  }

  getDrafts(d:any = false) {
    this.user_id = localStorage.getItem("client_data");
    const user = JSON.parse(this.user_id).user_type_id;

    let headers: any = {
      Authorization: `Bearer ${this.tokkenget}`,
      "My-Custom-Header": "foobar",
    };

    let data = {
      user_id: user,
      // page: page,
      // limit: limit
    };

    this.http.post(`${APIURL.BASE_URL}/user/get_drafts`, data, { headers }).subscribe((response: any) => {
      if (response.response.data.length > 0) {
        this.noDraftsMessage = "";
        this.noDrafts = false
        response.response.data.map((i: any, index: any) => {
          i.key = i.sub_key + " " + `${i.main_key == 0 ? "Major" : "Minor"}`
          if (i.tags) {
            i.fruits = i.tags.split('\n').map((tag: string) => tag.trim()).filter((tag: string) => tag !== '');
          } else {
            i.fruits = []
          }
          // console.log();

          if (i.usage_rights) {

          } else {
            i.usage_rights = this.default_usage
          }
          // console.log(this.drafts[index]);
          if(!d){

          i.zipProgress = this.drafts[index]?.zipProgress
          i.hidepausebutton = this.drafts[index]?.hidepausebutton
          i.zipfilename = this.drafts[index]?.zipfilename
          i.stempercentage = this.drafts[index]?.stempercentage
        }
        if (i.stems) {
          i.disable_input_as_itsloading2 =true
          i.zipfilename = i.stems
          }
        })
        this.drafts = response.response.data;
        // console.log(this.drafts);
        // console.log(this.drafts.length);

        if (this.drafts.length !== 0) {
          this.upload = false
        }

      } else {
        this.drafts = [];
        this.noDraftsMessage = "No loops uploaded.";
        this.noDrafts = true
      }
    });
  }
  //   extractBPM=(filename) =>{
  //     // Use a regular expression to find all numbers in the filename
  //     const numbers = filename.match(/\d+/g);

  //     if (!numbers) {
  //         // No numbers found
  //         return null;
  //     }

  //     // Convert found numbers from strings to integers
  //     const integers = numbers.map(num => parseInt(num, 10));

  //     // Loop through each number
  //     for (const number of integers) {
  //         // Check if the number has 2 or 3 digits
  //         if ((number >= 10 && number < 100) || (number >= 100 && number < 1000)) {
  //             return number;  // Return the first valid BPM
  //         }
  //     }

  //     return null;  // Return null if no valid BPM is found
  // }
  playAudio(item: any, index: any) {
    this.drafts[index].showWave = true
    this.player = index
    this.drafts.map((r: any) => {
      r.hidepausebutton = false;
    })
    this.drafts.map((r: any, i: any) => {
      if (r.audio) r.audio.pause()
      const element = document.querySelectorAll(
        "#player-row-" + i + " .play_button.pause"
      )[0];

      if (element && "click" in element) {
        (element as HTMLElement).click();
      }
    })


    // this.drafts[index].audio.addEventListener('timeupdate', () => {
    //   this.sliderValue = this.drafts[index].audio.currentTime;
    // });
    const element = document.querySelectorAll(
      "#player-row-" + index + " .play_button.play"
    )[0];

    // ////////console.log(element);
    var play_time: any = 0.00;

    play_time = document
      .querySelectorAll("#player-row-" + index + " .play_button.seek")[0]
      ?.getAttribute("data-seek-time");
    ////console.log(play_time);

    play_time = parseFloat(play_time).toFixed(2);
    if (!item.audio) {
      if (item.loop_type == 1) {
        if (item.tagged_loop_path !== null) {
          item.file = CONSTNAME.fILE_PATH + item.tagged_loop_path;
          this.durationofaudio = item.tagged_loop_duration;
        } else {
          item.file = CONSTNAME.fILE_PATH + item.loop_path;
          this.durationofaudio = item.loop_duration;
        }
      } else {
        item.file = CONSTNAME.fILE_PATH + item.tagged_loop_path;
        this.durationofaudio = item.tagged_loop_duration;
      }
      // if (!item.duration) {
      //   let sound: any;
      //   sound = await new Promise((resolve, reject) => {
      //     new p5.SoundFile(item.file, (s: any) => resolve(s), (err: any) => reject(err));
      //   });
      // }
      item.duration = this.durationofaudio;
      item.audio = new Audio(item.file);
      // item.audio.play();
      // console.log(element);

      if (element && "click" in element) {
        (element as HTMLElement).click();
      }
      item.audio.addEventListener('timeupdate', () => {
        this.sliderValue = item.audio.currentTime;
      });
      item.audio.addEventListener('ended', () => {
        // this.next();
        this.stopAudio(item, index)

      });
      if (item.duration >= 15) {
        this.above15second = true;
        this.apihitforstream = true

      }
      else if (item.duration < 15 && item.duration >= 5) {

        this.apihitforstream = true
        this.above5secondlessthan15 = true;
      } else if (item.duration > 1 && item.duration <= 5) {

        this.apihitforstream = true
        this.above1secondlessthan5 = true;
      }
    } else {
      item.audio.addEventListener('timeupdate', () => {
        this.sliderValue = item.audio.currentTime;
      });
      item.audio.addEventListener('ended', () => {
        this.stopAudio(item, index)
        // this.next();
      });
      if (element && "click" in element) {
        (element as HTMLElement).click();
      }
      // item.audio.play();
    }





    item.hidepausebutton = true;
    let r: any;
    this.id = setInterval(() => {
      r = item.audio.currentTime;
      if (r === item.duration || r > item.duration) {

        setTimeout(() => {

        }, 1000);
      }

      let dura: any = item.duration;
      if (item.duration >= 15) {
        if (r > 15) {
          if (this.apihitforstream == true) {
            // this.apihitforstreaming(item, r, dura);
            clearInterval(this.id);
            this.apihitforstream = false;

          }

        }
      } else if (item.duration < 15 && item.duration >= 5) {

        if (r > 5) {

          if (this.apihitforstream == true) {
            // this.apihitforstreaming(item, r, dura);
            clearInterval(this.id);
            this.apihitforstream = false;

          }
        }


      } else if (item.duration > 1 && item.duration <= 5) {
        if (r >= 1) {
          if (this.apihitforstream == true) {
            // this.apihitforstreaming(item, r, dura);
            clearInterval(this.id);
            this.apihitforstream = false;

          }

        }
      }
    }, 200);

  }
  stopAudio(item: any, index: any) {
    // ////console.log(item);
    this.drafts[index].showWave = false
    this.showWave = true
    if (item) {
      if (item.audio) {
        item.audio.pause()
        // this.showAudioPlayer = false;s
      }
      clearInterval(this.id);

      this.drafts.map((r: any) => {
        r.hidepausebutton = false;
      })
      const element = document.querySelectorAll(
        "#player-row-" + index + " .play_button.pause"
      )[0];
      // ////////console.log(element);

      if (element && "click" in element) {
        (element as HTMLElement).click();
      }
    } else {
      this.drafts?.map((r: any) => {
        if (r.audio) {
          r.audio.pause()
        }
        r.hidepausebutton = false;
      })
      const element = document.querySelectorAll(
        "#player-row-" + index + " .play_button.pause"
      )[0];
      // ////////console.log(element);

      if (element && "click" in element) {
        (element as HTMLElement).click();
      }
    }


  }
  selectFileImage(event: any, id: any) {
    return new Promise((resolve, reject) => {

      const file = event.target.files[0];
      if (!this.isValidArtworkSize(file)) {
        this.toastr.error("Artwork file size exceeds the maximum limit of 5MB. Please upload a smaller file.", "", { positionClass: 'toast-bottom-right' });
        return;
      }
      if (!this.isValidArtworkFile(file)) {
        this.toastr.error("Artwork file format not supported. Please upload artwork in .jpg or .png format.", "", { positionClass: 'toast-bottom-right' });
        return;
      }
      this.image = event.target.files[0].name
      //console.warn(this.profile_image_pathofcomments + this.image);
      this.ImagefileName = file.name
      this.user_id = localStorage.getItem("client_data");
      const user = JSON.parse(this.user_id).user_type_id;
      // ("hey_ram", user);
      // if(file)
      // this.loadImage(file);
      let extensiontype = file.name.split(".").pop();
      // if(extensiontype=='.png'||extensiontype=='.jpeg'||extensiontype=='.jpg'){
      this.imageProgress = true;
      // }
      let tokkenget: any = localStorage.getItem("clientintoken");
      const formData = new FormData();
      formData.append("loop_artwork", file);
      formData.append("loop_id", id);

      let headers: any = {
        Authorization: `Bearer ${tokkenget}`,
        "My-Custom-Header": "foobar",
      };

      this.http
        .post(`${APIURL.BASE_URL}/user/upload_loop_draft`, formData, {
          headers: headers,
          reportProgress: true,
          observe: "events"
        }).pipe(takeUntil(this.cancelUpload3))
        .subscribe((e: any) => {
          if (e.type === HttpEventType.UploadProgress && e.loaded && e.total) {
            const newPercentage = Math.round((e.loaded / e.total) * 100);

            if (this.imgpercentage !== newPercentage) {
              this.imgpercentage = newPercentage;
              //console.log(this.imgpercentage);
            }

            if (this.imgpercentage === 100) {
              this.imageProgress = false;
              this.getDrafts()
            }
          } else if (e.type === HttpEventType.Response) {
            // Handle the final response here if needed
            if (e.body?.response?.status) {
              this.imageProgress = false;
              this.imgpercentage = 0
              this.imageuploaded = e.body.response.data
            } else {
              this.handleError(e.body?.response?.errors);
            }
            this.getDrafts()
          }
        }, (error) => {
          this.imageProgress = false
          if (error.status === 0) {
            this.toastr.error("Network connection lost. Please check your connection and try uploading again.", "", { positionClass: 'toast-bottom-right' });
          } else {
            this.toastr.error("An error occurred during the upload. Please try again.", "", { positionClass: 'toast-bottom-right' });
          }
          reject(error);
        }
        )
    }
    );
  }
  handleError(errors: any) {
    throw new Error('Method not implemented.');
  }
  keychange(e: any, id: any) {
    //console.log(e.target.value);
    const match = e.target.value.match(/(.+?)\s(Major|Minor)/);
    //console.log(match);
    let sendData = {
      "sub_key": match[1],
      "main_key": match[2] == "Major" ? 0 : 1,
      "loop_id": id,
    }
    let headers: any = {
      Authorization: `Bearer ${this.tokkenget}`,
      "My-Custom-Header": "foobar",
    };
    this.http
      .post(`${APIURL.BASE_URL}/user/upload_loop_test`, sendData, {
        headers: headers,
      })
      .pipe()
      .subscribe((event: any) => { })
  }
  openEdit(items: any) {
    items.isEditing = true
  }
  updateName(e: any, id: any) {
    let sendData = {
      "title": e.target.value,
      "loop_id": id,
    }
    if (e.target.value.length > 50) {
      this.toastr.error("Please enter title less than 50 characters", "", { positionClass: 'toast-bottom-right' })

    } else {
      let headers: any = {
        Authorization: `Bearer ${this.tokkenget}`,
        "My-Custom-Header": "foobar",
      };
      this.http
        .post(`${APIURL.BASE_URL}/user/upload_loop_test`, sendData, {
          headers: headers,
        })
        .pipe()
        .subscribe((event: any) => {
          this.getDrafts()
        })
    }
  }
  updateBpm(e: any, id: any) {
    let sendData = {
      "bpm": e.target.value,
      "loop_id": id,
    }
    let headers: any = {
      Authorization: `Bearer ${this.tokkenget}`,
      "My-Custom-Header": "foobar",
    };
    this.http
      .post(`${APIURL.BASE_URL}/user/upload_loop_test`, sendData, {
        headers: headers,
      })
      .pipe()
      .subscribe((event: any) => {
        this.getDrafts()
      })
  }
  updateVisible(id: any, i: any) {
    let sendData = {
      "visiblity": this.drafts[i].visiblity ? 1 : 0,
      "loop_id": id,
    }
    let headers: any = {
      Authorization: `Bearer ${this.tokkenget}`,
      "My-Custom-Header": "foobar",
    };
    this.http
      .post(`${APIURL.BASE_URL}/user/upload_loop_test`, sendData, {
        headers: headers,
      })
      .pipe()
      .subscribe((event: any) => {
        this.getDrafts()
      })
  }
  updateUsage(e: any, id: any) {
    this.usage = e.target.value
    this.applyall = false
    let sendData = {
      "usage_rights": e.target.value || null,
      "loop_id": id,
      "all": false
    }
    let headers: any = {
      Authorization: `Bearer ${this.tokkenget}`,
      "My-Custom-Header": "foobar",
    };
    this.http
      .post(`${APIURL.BASE_URL}/user/upload_loop_test`, sendData, {
        headers: headers,
      })
      .pipe()
      .subscribe((event: any) => {
        this.getDrafts()
      })
  }
  ApplytoAll() {
    let sendData = {
      "usage_right": this.usage || null,
      "user_id": JSON.parse(this.user_id).user_type_id
    }
    let headers: any = {
      Authorization: `Bearer ${this.tokkenget}`,
      "My-Custom-Header": "foobar",
    };
    this.http
      .post(`${APIURL.BASE_URL}/user/apply_to_all`, sendData, {
        headers: headers,
      })
      .pipe()
      .subscribe((event: any) => {
        this.applyall = false
        this.getDrafts()
      })
  }
  show(i: any) {
    //console.log(this.drafts[i]);
    this.drafts[i].showinput = true
  }
  add(event: MatChipInputEvent, item: any): void {
    const value = (event.value || '\n').trim();

    // Add our fruit
    if (value) {
      this.fruits.push(value);
      this.drafts[item].fruits.push(value)
    }

    // Clear the input value
    // event.chipInput!.clear();

    this.fruitCtrl.setValue(null);
    this.checktag(this.drafts[item].fruits, this.drafts[item].loop_id, item);
  }
  checktag(value: any = null, loop: any, i: any) {

    var nameofuser = value;
    var values = value;
    let tolower = function (x: any) {
      return x.toLowerCase();
    };
    values = values.map(tolower);
    if (values.length > 3) {
    } else {
      this.tagvalid = true;
      this.tag1 = values[0]
      this.tag2 = values[1]
      this.tag3 = values[2]
      this.UpdateTags(loop, i)
    }
  }
  remove(fruit: string, loop: any, i: any): void {
    // console.log("Ss");
    const index = this.drafts[i].fruits.indexOf(fruit);
    // console.log(index);
    let a = this.drafts[i].tags
    // console.log(a);

    let strtag: any = a.split('\n');
    let strtag1: any = strtag[0];
    let strtag2: any = strtag[1];
    let strtag3: any = strtag[2];
    if (strtag1 === fruit) {
      this.tag1 = null
    }
    if (strtag2 === fruit) {
      this.tag2 = null
    }
    if (strtag3 === fruit) {
      this.tag3 = null
    }
    if (index >= 0) {
      this.drafts[i].fruits.splice(index, 1);
    }
    this.UpdateTags(loop, i)
    // console.log(this.drafts[i].fruits);

  }
  UpdateTags(id: any, i: any) {
    let sendData = {
      "tags": this.drafts[i].fruits,
      "loop_id": id,
    }
    // console.log(sendData);

    let headers: any = {
      Authorization: `Bearer ${this.tokkenget}`,
      "My-Custom-Header": "foobar",
    };
    this.http
      .post(`${APIURL.BASE_URL}/user/upload_loop_test`, sendData, {
        headers: headers,
      })
      .pipe()
      .subscribe((event: any) => {
        this.getDrafts()
      })
  }
  delete(id: any, index: any) {
    if (this.drafts[index].zipProgress) {
      this.toastr.error("Zip Upload is in progress", "", { positionClass: 'toast-bottom-right' })

    } else {
      let dialogue = this.matDialog.open(DeleteComponent, { panelClass: 'delete-loop', data: { loop_id: id } });
      dialogue.afterClosed().subscribe((result: any) => {
        // ////console.log('The dialog was closed');
        this.user_id = localStorage.getItem("client_data");
        const user = JSON.parse(this.user_id).user_type_id;
        this.handleCleanup()
        this.getDrafts(true)
        // this.router.navigate(['user/sounds'],{queryParams:{user_id:user}})
        // if(result)
        // this.dialogRef.close({result:result});

      });
    }

  }
  openConfirm() {
    let prog = false
    if (this.loopprogress) {
      this.toastr.error("Loops upload is in progress", "", { positionClass: 'toast-bottom-right' });
      return
    }
    this.upload = true
    this.drafts.map((i: any) => {
      if (i.zipProgress === true) {
        prog = true
        this.toastr.error("Zip upload is in progress", "", { positionClass: 'toast-bottom-right' });
        return
      }
    })
    if (!prog) {
      let dialogue = this.matDialog.open(ConfirmComponent, { panelClass: 'confirm-upload', data: {} });
      dialogue.afterClosed().subscribe((result: any) => {
        this.getDrafts()
      });
    }

  }


  extractBPM(filename: any) {
    // Define a regular expression to match BPM patterns in the filename
    // // const bpmPattern = /(?:^|\s|_)(\d{2,3})(?:\s*BPM|_BPM|_bpm|_|\s*BPM)?(?:\W|$)/i;
    // const bpmPattern = /(?:^|\s|_|\b)(\d{2,3})(?:\s*BPM|_bpm|_BPM|_|\b)?(?:\s|_|$)/i;
    // // Test the filename against the regex pattern
    // const match = filename.split('_')

    // // If a match is found, return the BPM value; otherwise, return null
    // return match ? parseInt(match[1], 10) : null;

    // Use a regular expression to find all numbers in the filename
    const numbers = filename.match(/\d+/g);

    if (!numbers) {
      // No numbers found
      return null;
    }

    // Convert found numbers from strings to integers
    const integers = numbers.map((num: string) => parseInt(num, 10));

    // Loop through each number
    for (const number of integers) {
      // Check if the number has 2 or 3 digits
      if ((number >= 10 && number < 100) || (number >= 100 && number < 1000)) {
        return number;  // Return the first valid BPM
      }
    }

    return null;  // Return null if no valid BPM is found
  }
}
